import { CarsActions, CarsActionTypes, CarsState } from "./cars_types";
import { ICar } from "../car/car_types";
import { IPoint } from "redux/map/map_types";
import { getDistanceBetween } from "components/map/mapTypes";

const initialState: CarsState = {
  loading: true,
  cars: [],
  filter: "",
  message: "",
  messageError: "",
  carsProvider: [],
};

const CarsReducer = (state = { ...initialState }, action: CarsActions) => {
  switch (action.type) {
    case CarsActionTypes.GET_CARS:
      return {
        ...state,
        cars: action.payload,
        loading: false,
      };

    case CarsActionTypes.CARS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case CarsActionTypes.CARS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    case CarsActionTypes.CARS_RESET:
      return {
        ...initialState,
      };
    case CarsActionTypes.MESSAGE_SUCCESS_CARS:
      return {
        ...state,
        message: action.payload,
      };
    case CarsActionTypes.MESSAGE_ERROR_CARS:
      return {
        ...state,
        messageError: action.payload,
      };

    case CarsActionTypes.POST_ACCESS_CAR_TO_USER:
      return {
        ...state,
      };
    case CarsActionTypes.GET_CARS_PROVIDERS_USER:
      return {
        ...state,
        carsProvider: action.payload,
      };

    case CarsActionTypes.CARS_UPDATE_ADDRESS: {
      const newCars: ICar[] = [];

      for (let index = 0; index < state.cars.length; index++) {
        if (!state.cars[index].last_point || state.cars[index].last_point?.address) {
          newCars.push({ ...state.cars[index] });
          continue;
        }

        if (
          state.cars[index].last_point?.lat === action.payload.lat &&
          state.cars[index].last_point?.long === action.payload.long
        ) {
          const newLastPoint = { ...state.cars[index].last_point } as IPoint;
          newLastPoint.address = action.payload.address;
          newCars.push({ ...state.cars[index], last_point: newLastPoint });
          continue;
        }

        newCars.push({ ...state.cars[index] });
      }

      return {
        ...state,
        cars: newCars,
      };
    }

    case CarsActionTypes.CARS_UPDATE_FIELD: {
      const newCars: ICar[] = [...state.cars];

      for (const car of newCars) {
        if (car.id === action.payload.id) {
          if (action.payload.field !== "last_point") {
            car[action.payload.field] = action.payload.value;
            continue;
          }

          // LAST_POINT
          const newLastPoint = { ...action.payload.value };
          if (car.last_point !== null && car.last_point?.address) {
            const a = {
              x: car.last_point?.lat_address ?? car.last_point.lat,
              y: car.last_point?.long_address ?? car.last_point.long,
            };

            const b = {
              x: newLastPoint.lat,
              y: newLastPoint.long,
            };

            const dist = getDistanceBetween(a, b);
            // console.log(`${car.car_number} ухал на ${dist} м со скоростью ${newLastPoint.speed}`);

            const slittingDistance =
              newLastPoint.speed > 50 ? (newLastPoint.speed > 80 ? 1700 : 1000) : 500; // какое расстояние считать разрывом (м)

            if (dist < slittingDistance) {
              // если расстояние мелкое, то оставляем старый адрес
              newLastPoint.lat_address = car.last_point?.lat_address ?? car.last_point.lat;
              newLastPoint.long_address = car.last_point?.long_address ?? car.last_point.long;
              newLastPoint.address = car.last_point.address;
            }
          }

          car.last_point = newLastPoint;
        }
      }

      return {
        ...state,
        cars: newCars,
      };
    }

    default:
      return state;
  }
};

export default CarsReducer;
