import CarAddress from "components/cars/car/CarAddress";
import EventColumn from "components/events/EventColumn";
import { ReactComponent as Location } from "../../../assets/images/location.svg";
// import PointAdress from "../PointAdress";
import MapComponent from "../mapOpenBallonPoint/MapComponent";
import { useState } from "react";
import BalloonHandler from "../mapOpenBallonPoint/BalloonHandler";
import PointAdressButton from "../mapOpenBallonPoint/PointAdressButton";

const PointStop = ({
  start,
  end,
  isDate,
  period,
  address,
  date,
  pointId,
  coordinate,
}: IPointStop) => {
  const [myMap, setMyMap] = useState(null);
  const { centerMapOnCoordinates } = BalloonHandler({ myMap });
  return (
    <div className="stop">
      <div className="stop_pic">
        <div className="stop_line"></div>
      </div>
      <div className="stop_box">
        <div className="report_data report_data--single">
          <div className="report_data-col ">
            <div className="report_title report_info-title">Стоянка</div>
            <div className="report_info report_info-three">
              {!isDate ? (
                <EventColumn title="начало стоянки" data={date} smallTitle="" />
              ) : (
                <EventColumn title="начало стоянки" data={start} smallTitle="" />
              )}
              <EventColumn title="завершение стоянки" data={end} smallTitle="" />
              <EventColumn title="общее время" data={period} smallTitle="" />
            </div>
            <div className="stop_address">
              <MapComponent onMapReady={setMyMap} />
              <PointAdressButton coordinate={coordinate} onShwBallon={centerMapOnCoordinates}>
                <Location className="address_logo address_logo--mini" />
                <CarAddress classAddress="address_text" address={coordinate} />
              </PointAdressButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointStop;

export interface IPointStop {
  start: string;
  end: string;
  isDate: boolean;
  period: string;
  address: string | null;
  date: string;
  pointId: number;
  coordinate: [number, number];
}
