import SkeletonLoading from "../../skeleton-loading/SkeletonLoading";
import { VehicleAddress } from "../Cars.styled";

const CarAddress = ({
  address,
}: {
  address: any; ///undefined | null | string |
  classAddress?: string;
}) => {
  // if (address === null)
  //   return (
  //     <VehicleAddress>
  //       <SkeletonLoading height="10px" />
  //     </VehicleAddress>
  //   );
  /// раскоментировать когда будет решен вопрос с отображение адреса

  return <VehicleAddress>{address}</VehicleAddress>;
};

export default CarAddress;
