import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
const UsersAdminPage = lazy(() => import("../pages/admin/UsersAdminPage"));
const PingAdminPage = lazy(() => import("../pages/admin/PingAdminPage"));
const ProfilesAdminPage = lazy(() => import("../pages/admin/ProfilesAdminPage"));
import PrivateLayout from "../layouts/PrivateLayout";
import ProfileLayout from "../layouts/ProfileLayout";
import ViewCarLayout from "../layouts/ViewCarLayout";
import ConstructorLayout from "../layouts/ConstructorLayout";
const ConstructorPage = lazy(() => import("../pages/ConstructorPage"));
import Dashboard from "../components/dashboard/Dashboard";
import DashboardAdminPage from "../pages/admin/DashboardAdminPage";
import DevicesAdminPage from "../pages/admin/DevicesAdminPage";
// const DashboardPage = lazy(() => import("../pages/DashboardPage"));
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from "../pages/ProfilePage";
import TestPage from "../pages/TestPage";
import ViewCarEventsPage from "../pages/view-car/ViewCarEventsPage";
import ViewCarReportPage from "../pages/view-car/ViewCarReportPage";
import ViewCarSensorsPage from "../pages/view-car/ViewCarSensorsPage";
import ViewCarStatusPage from "../pages/view-car/ViewCarStatusPage";
import NotFound from "../pages/NotFound";

import { ModalUserCarProvider } from "components/user/context/ModalUserCarContext";
import TyresPage from "pages/TyresPage";
import TyresLayout from "layouts/TyresLayout";
import TyresRepairPage from "pages/tires/TyresRepairPage";
import TyresJournalPage from "pages/actions/JournalPage";
import TyresStorePage from "pages/tires/TyresStorePage";
import TyresUtilizationPage from "pages/tires/TyresUtilizationPage";
import TyresActionLayout from "layouts/TyresActionLayout";
import TyrePage from "pages/TyrePage";
import TyresLayoutWithReason from "layouts/TyresLayoutWithReason";
import TyresHistoryPage from "pages/tires/TyresHistoryPage";
import TyresPageStart from "pages/TyresPageStart";

const PrivateRoutes = () => (
  <ModalUserCarProvider>
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route element={<DashboardPage />}>
          {/* панель управления */}
          <Route path="/" element={<Dashboard />} />
          {/* зашли в машину */}
          <Route path="/view/:vehicle_id" element={<ViewCarLayout />}>
            <Route index element={<ViewCarStatusPage />} />
            <Route path="/view/:vehicle_id/sensors" element={<ViewCarSensorsPage />} />
            <Route path="/view/:vehicle_id/report" element={<ViewCarReportPage />} />
            <Route path="/view/:vehicle_id/events" element={<ViewCarEventsPage />} />
          </Route>
        </Route>

        {/* страница для тестов */}
        <Route path="/test" element={<TestPage />} />

        {/* о себе */}
        <Route element={<ProfileLayout />}>
          <Route path="/profile" element={<ProfilePage />} />
        </Route>

        {/* журнал шин */}
        <Route element={<TyresLayout />}>
          <Route path="/tyres" element={<TyresPageStart />} />
          {/* <Route path="//tyres/repair"  element={<TyresPageStart />} /> */}
          {/* <Route path="/tyres/history"  element={<TyresPageStart />} /> */}
          <Route path="/tyres/:vehicle_id" element={<TyresPage />} />
          <Route path="/tyres/repair/:vehicle_id" element={<TyresRepairPage />} />
          <Route path="/tyres/history/:vehicle_id" element={<TyresHistoryPage />} />
        </Route>

        <Route path="/tyres/journal" element={<TyresJournalPage />} />
        <Route element={<TyresActionLayout isTyre={true} />}>
          <Route path="/tyres/:vehicle_id/:tyre_id" element={<TyrePage />} />
        </Route>
        <Route element={<TyresLayoutWithReason />}>
          <Route path="/tyres/store" element={<TyresStorePage />} />
          <Route path="/tyres/utilization" element={<TyresUtilizationPage />} />
        </Route>

        {/* конструктор */}
        <Route element={<ConstructorLayout />}>
          <Route path="/constructor/:vehicle_id" element={<ConstructorPage />} />
        </Route>

        {/* АДМИНКА */}
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<DashboardAdminPage />} />
          <Route path="/admin/profiles" element={<ProfilesAdminPage />} />
          <Route path="/admin/users" element={<UsersAdminPage />} />
          <Route path="/admin/devices" element={<DevicesAdminPage />} />
          <Route path="/admin/ping" element={<PingAdminPage />} />
        </Route>
      </Route>

      <Route path="/login" element={<Navigate to="/" />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </ModalUserCarProvider>
);

export default PrivateRoutes;
