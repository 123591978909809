import { useActions } from "hooks/useAction";
import ModalWindow from "./ModalWindow";
import { ModalsState } from "redux/modals/modals_types";
import { useTypedSelector } from "hooks/useTypedSelector";
import cls from "./style/StyleTransferCars.module.scss";
import { useEffect, useState } from "react";
import { useGetUsersQuery } from "redux/admin/users/UsersApi";
import toast from "react-hot-toast";

const ModalTransferCars = () => {
  const { toggleModal, getUsers, getUsersFilter, postAccessCarsToUser } = useActions();
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const { message } = useTypedSelector((state) => state.cars);
  const { usersFiltered } = useTypedSelector((state) => state.userFilter);
  const [owner, setOwner] = useState<any>("");
  const [accessUser, setAccessUser] = useState<any>("");
  const [dataSearchOwner, setDataSearchOwner] = useState<any>([]);
  const [dataSearchAccessUser, setDataSearchAccessUser] = useState<any>([]);
  const [formAccess, setFormAccess] = useState<any>({
    owner_id: "",
    user_id: "",
  });
  // const [ownerStatus, setOwnerStatus] = useState<boolean>(false);
  // const [accessUserStatus, setAccessUserStatus] = useState<boolean>(false);

  const handleInputOwner = (e: any) => {
    const data = e.value;
    setOwner(data);
  };
  const handleInputAccessUser = (e: any) => {
    const data = e.value;
    setAccessUser(data);
  };
  useEffect(() => {
    const filteredItemsOwner = usersFiltered.filter((item) =>
      item.name.toLowerCase().includes(owner.toLowerCase())
    );
    const filteredItemsAccess = usersFiltered.filter((item) =>
      item.name.toLowerCase().includes(accessUser.toLowerCase())
    );
    setDataSearchOwner(filteredItemsOwner);
    setDataSearchAccessUser(filteredItemsAccess);
  }, [owner, usersFiltered, accessUser]);

  const handleClose = () => {
    setFormAccess({
      owner_id: "",
      user_id: "",
    });
    setOwner("");
    setAccessUser("");
    toggleModal({ toggle: false, modal: "modal_transfer_cars" });
  };

  const handleClick = (id: number, context: string) => {
    if (context === "owner") {
      setFormAccess((prev: any) => ({
        ...prev,
        owner_id: prev.owner_id === id ? "" : id,
      }));
    } else if (context === "user") {
      setFormAccess((prev: any) => ({
        ...prev,
        user_id: prev.user_id === id ? "" : id,
      }));
    }
  };

  const handleSubmit = () => {
    const hasEmptyValues = Object.values(formAccess).some(
      (el) => el === "" || el === null || el === undefined
    );
    if (hasEmptyValues) {
      toast.error("Один из пользователей не выбран ");
      return;
    }
    postAccessCarsToUser(formAccess);
    toast.success("Доступ предоставлен");
    handleClose();
  };

  const renderCardUser = (el: any, context: string) => {
    const isOwnerActive = formAccess.owner_id === el.id;
    const isUserActive = formAccess.user_id === el.id;

    return (
      <div
        key={el.id}
        className={`${isOwnerActive ? cls.activCard : ""} ${isUserActive ? cls.activCard : ""} ${
          cls.ctnUser
        }`}
        onClick={() => handleClick(el.id, context)}
      >
        <div className={`${cls.textCard} ${cls.idCard}`}>
          <div>Id:</div>
          <strong>{el.id}</strong>
        </div>
        <div className={cls.textCard}>
          <div>Name:</div>
          <strong>{el.name}</strong>
        </div>
        <div className={cls.textCard}>
          <div>Email:</div>
          <strong>{el.email}</strong>
        </div>
        <div className={cls.checkbox}></div>
      </div>
    );
  };
  return (
    <>
      <ModalWindow
        isShow={modals.modal_transfer_cars}
        title="Доступ к транспорту"
        dialogClassName="modal_box-big"
        handleClose={handleClose}
      >
        <div className={cls.ctnContent}>
          <div className={cls.text}>Владелец:</div>
          <div className={cls.ctnInput}>
            <input className={cls.input} type="text" onChange={(e) => handleInputOwner(e.target)} />
          </div>
          <div className={cls.ctnInputSearch}>
            {owner ? (
              dataSearchOwner.map((e: any) => renderCardUser(e, "owner"))
            ) : (
              <div className={cls.textEmpty}>Введите Имя</div>
            )}
          </div>
          <hr />
          <div className={cls.text}>Кому:</div>
          <div className={cls.ctnInput}>
            <input
              className={cls.input}
              type="text"
              onChange={(e) => handleInputAccessUser(e.target)}
            />
          </div>
          <div className={cls.ctnInputSearch}>
            {accessUser ? (
              dataSearchAccessUser.map((e: any) => renderCardUser(e, "user"))
            ) : (
              <div className={cls.textEmpty}>Введите Имя</div>
            )}
          </div>
          <div className={cls.CtnButton}>
            <button className="modal__button " onClick={handleSubmit}>
              Привязать транспорт
            </button>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

export default ModalTransferCars;
