import { ICar, RequestForAccessType } from "../car/car_types";
import { IFolder } from "redux/folder/FolderType";
import { IPaginate, IServerResponse } from "../../types/other";

export interface CarsState {
  cars: ICar[];
  filter: string;
  loading: boolean;
  message: string;
  messageError: string;
  carsProvider: ICar[];
}

export interface IFolderResponse extends IServerResponse {
  paginate: IPaginate;
  groups: IFolder[];
}

export enum CarsActionTypes {
  GET_CARS = "GET_CARS",
  CARS_LOADING = "CARS_LOADING",
  CARS_FILTER = "CARS_FILTER",
  CARS_RESET = "CARS_RESET",
  CARS_UPDATE_ADDRESS = "CARS_UPDATE_ADDRESS",
  CARS_UPDATE_FIELD = "CARS_UPDATE_FIELD",
  POST_ACCESS_CAR_TO_USER = "POST_ACCESS_CAR_TO_USER",
  MESSAGE_SUCCESS_CARS = "MESSAGE_SUCCESS_CARS",
  MESSAGE_ERROR_CARS = "MESSAGE_ERROR_CARS",
  GET_CARS_PROVIDERS_USER = "GET_CARS_PROVIDERS_USER",
}

interface GetCars {
  type: CarsActionTypes.GET_CARS;
  payload: ICar[];
}

interface CarsLoading {
  type: CarsActionTypes.CARS_LOADING;
  payload: boolean;
}

interface CarsFilter {
  type: CarsActionTypes.CARS_FILTER;
  payload: string;
}

interface CarsReset {
  type: CarsActionTypes.CARS_RESET;
}

export interface CarsUpdateAddressPayload {
  lat: number;
  long: number;
  address: string;
}

export interface CarsUpdateFieldPayload {
  id: number;
  field: string;
  value: any;
}

interface CarsUpdateAddress {
  type: CarsActionTypes.CARS_UPDATE_ADDRESS;
  payload: CarsUpdateAddressPayload;
}

interface CarsUpdateField {
  type: CarsActionTypes.CARS_UPDATE_FIELD;
  payload: CarsUpdateFieldPayload;
}

interface PostAccessCarToUser {
  type: CarsActionTypes.POST_ACCESS_CAR_TO_USER;
  payload: RequestForAccessType;
}

interface getCarsProvidersUser {
  type: CarsActionTypes.GET_CARS_PROVIDERS_USER;
  payload: ICar[];
}

interface MessageSuccessCars {
  type: CarsActionTypes.MESSAGE_SUCCESS_CARS;
  payload: string;
}

interface ErrorMessageCars {
  type: CarsActionTypes.MESSAGE_ERROR_CARS;
  payload: string;
}

export type CarsActions =
  | getCarsProvidersUser
  | MessageSuccessCars
  | PostAccessCarToUser
  | GetCars
  | CarsLoading
  | CarsFilter
  | CarsReset
  | ErrorMessageCars
  | CarsUpdateAddress
  | CarsUpdateField;
