import axios from "axios";
import { ICar, RequestForAccessType } from "../redux/car/car_types";
import {
  GetLastSettingsData,
  GetPeriodSettingsData,
  PostTransportSettings,
} from "../redux/settings/settings_types";
import { ICarsResponse, IGetNotifsResponse } from "../types/responses";
import { IDesignParams } from "../helpers/constructorTypes";

export default {
  login: async (email: string, password: string) =>
    axios.post(`/login`, { email, password }).then((res) => res),

  signUp: async (name: string, email: string, password: string) =>
    axios.post("/sign_up", { name, email, password }).then((res) => res),

  getMe: async () => axios.post("/users/me", { with: ["profile"] }).then((res) => res),

  putUser: async (name: string) => axios.put("/users/me", { name }).then((res) => res),

  sendConfirmation: async () => axios.post("/users/send_confirmation").then((res) => res),

  userResetPassword: async (email: string) =>
    axios.post("/users/reset_password", { email }).then((res) => res),

  userConfirmPassword: async (code: string, password: string) =>
    axios
      .post("/users/confirm_password", {
        code,
        password,
      })
      .then((res) => res),

  userChangePassword: async (password: string, new_password: string) =>
    axios
      .post("/users/change_password", {
        password,
        new_password,
      })
      .then((res) => res),
  // /visible vehicles
  getCars: async () =>
    axios
      .get<ICarsResponse>("/visible", {
        params: {
          with: ["last_point", "last_limit", "design"],
        },
      })
      .then((res) => res.data),

  getCar: async (id: number) =>
    axios
      .get(`/vehicles/${id}`, {
        params: {
          with: ["last_limit", "last_point", "design"],
        },
      })
      .then((res) => res),

  addCar: async (data: any) => axios.post(`/vehicles`, data).then((res) => res),

  putCar: async (id: number, data: any) => axios.put(`/vehicles/${id}`, data).then((res) => res),

  deleteCar: async (id: number) => axios.delete(`/vehicles/${id}`).then((res) => res),

  getUserCars: async (id: number) =>
    axios
      .get<ICarsResponse>(`/vehicles_from_user/${id}`, {
        params: {
          with: ["last_limit", "last_point", "design"],
        },
      })
      .then((res) => res.data),

  addUserCars: async (data: any) =>
    axios.post<ICarsResponse>(`/vehicles_to_user`, data).then((res) => res.data),

  postUserCarsAccess: async (data: RequestForAccessType) =>
    axios.post(`/users/share`, data).then((res) => res),

  postVisibleCarsToUser: async () => axios.get(`/visible`).then((res) => res),

  getLastPoint: async (device_id: number) =>
    axios.get(`/points/last/${device_id}`).then((res) => res),

  getPoint: async (id: number) => axios.get(`/points/${id}`).then((res) => res),

  getPointStops: async (device_id: number, from: string, to: string) =>
    axios.post(`/points/stops`, { device_id, from, to }).then((res) => res.data),

  getPointsCount: async (id: number) => axios.get(`/points/count/${id}`).then((res) => res),

  deletePoints: async (id: number) => axios.delete(`/points/${id}`).then((res) => res),

  getLimits: async () => axios.get(`/limits`).then((res) => res),

  postLimits: async (data: PostTransportSettings) => axios.post(`/limits`, data).then((res) => res),

  getLastLimits: async (data: GetLastSettingsData) =>
    axios.post(`/limits/last`, data).then((res) => res),

  getPeriodLimits: async (data: GetPeriodSettingsData) =>
    axios.post(`/limits/period`, data).then((res) => res),

  getNotifs: async () => axios.get<IGetNotifsResponse>(`/notifications`).then((res) => res),

  postNotif: async (data: any) => axios.post("/notifications", data).then((res) => res),

  putNotification: async (is_instant: boolean, notification_id: number) =>
    axios.put(`/notifications/${notification_id}`, { is_instant }).then((res) => res.data),

  deleteNotif: async (notification_id: number) =>
    axios.delete(`/notifications/${notification_id}`).then((res) => res.data),

  getEvents: async () => axios.get("/events").then((res) => res),

  getEvent: async (id: number) => axios.get(`/events/${id}`).then((res) => res),

  getEventsPeriod: async (from: string, to: string, vehicles: number[], page: number) =>
    axios
      .post("/events/period", {
        from,
        to,
        vehicles,
        page,
        with: ["limit", "point"],
      })
      .then((res) => res),

  getEventsLast: async (vehicle_id: number) =>
    axios
      .post("/events/last", {
        vehicle_id,
        with: ["limit", "point"],
      })
      .then((res) => res),

  deleteEvent: async (id: number) => axios.delete(`/events/${id}`).then((res) => res),

  feedBack: async (data: any) =>
    axios
      .post("/feedback", {
        ...data,
      })
      .then((res) => res),

  getAddress: async (data: any) =>
    axios.post("address/get_address", { ...data }).then((res) => res),

  getReportJSON: (from: string, to: string, vehicle_id: number, format: string) =>
    axios.post(`/report/events`, { from, to, vehicle_id, format }).then((res) => res.data),

  getReportPDF: (from: string, to: string, vehicle_id: number, format: string) =>
    axios({
      url: "/report/events",
      method: "POST",
      responseType: "blob",
      params: { from, to, vehicle_id, format },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report ${from} - ${to}.pdf`);
      document.body.appendChild(link);
      link.click();
    }),

  getPointsPeriod: (device_id: number, from: string, to: string, page = 1) =>
    axios.post(`/points/period`, { device_id, from, to, page }).then((res) => res.data),

  getPointDetailed: (point_id: number) =>
    axios.post(`/points/detailed/${point_id}`).then((res) => res.data),

  getCalendar: (device_id: number, from: string) =>
    axios.post(`/calendar`, { device_id, from, with: ["events"] }).then((res) => res.data),

  postDesign: (data: IDesignParams) => axios.post("/design", data).then((res) => res.data),

  getDesign: (vehicle_id: number) => axios.get(`/design/${vehicle_id}`).then((res) => res.data),

  getUsers: () => `/users`,

  createNewUser: () => `/users/new_user`,

  getDevices: () => `/devices`,

  putDevices: (id: number) => `devices/${id}`,

  postDevice: () => `/devices`,

  deleteDevice: (device_id: number) => `/devices/${device_id}`,

  getFolders: () => `/folders`,

  getFoldersUser: (id: number) => `/folders_from_user/${id}`,

  addFolder: () => `/folders`,

  deleteFolder: (id: number) => `/folders/${id}`,

  putFolder: (id: number) => `/folders/${id}`,

  postNewPasswordUserByModerator: () => `/users/new_password`,

  getProfiles: () => `/profiles`,

  postAddProfile: () => `/profiles`,

  getProfile: (id: number) => `/profiles/${id}`,

  getChangeProfile: (id: number) => `/profiles/${id}`,

  deleteProfile: (id: number) => `/profiles/${id}`,

  changeUser: (id: number) => `/users/${id}`,

  //tyres
  getTyres: async () => axios.get("/tyres").then((res) => res.data),

  getCarTyres: async (id: number) => axios.get(`/tyres_by_vehicle/${id}`).then((res) => res.data),

  getTyre: async (id: number) =>
    axios
      .get(`/tyres/${id}`, {
        params: {
          with: ["last_action", "last_move"],
        },
      })
      .then((res) => res.data),

  addTyre: async (data: any) => axios.post(`/tyres`, data).then((res) => res.data),

  putTyre: async (id: number, data: any) => axios.put(`/tyres/${id}`, data).then((res) => res),

  deleteTyre: async (id: number) => axios.delete(`/tyres/${id}`).then((res) => res),

  addActionAdding: async (data: any) => axios.post(`/actions/adding`, data).then((res) => res),

  getTyresDeleted: async () => axios.get(`/tyres/utilization`).then((res) => res.data),

  getTyresStored: async () => axios.get(`/tyres/store`).then((res) => res.data),

  getActionsPeriod: async (from: string, to: string, type: string | undefined) =>
    axios.post(`/actions/period`, { from, to }, { params: { type } }).then((res) => res.data),

  getLastAction: async (id: number) => axios.get(`/actions/last/${id}`).then((res) => res.data),

  postTyresByPeriod: async (from: string, to: string, type: string | undefined) =>
    axios.post(`/tyres/period`, { from, to }, { params: { type } }).then((res) => res.data),

  postActionsTyreByPeriod: async (from: string, to: string, id: number) =>
    axios.post(`/actions/period/tyre/${id}`, { from, to }).then((res) => res.data),

  postAction: async (data: any, type: string) =>
    axios.post(`/actions/${type}`, data).then((res) => res.data),

  getActionsByVehicle: async (vehicle_id: number) =>
    axios.get(`/actions/vehicle/${vehicle_id}`).then((res) => res.data),

  getActionsByVehicleWithType: async (vehicle_id: number, type: string) =>
    axios.get(`/actions/vehicle/${vehicle_id}/${type}`).then((res) => res.data),
};
